import React from 'react'
import Link from 'next/link'
import { Typography, Card } from 'antd'
import useT from '@/hooks/useT'
import { LocalizedString } from '@maphubs/mhtypes'

const { Title } = Typography

const LinkCard = ({
  title,
  href,
  icon
}: {
  title: string | LocalizedString
  href: string
  icon: React.Node
}): React.FC => {
  const { t } = useT()
  return (
    <Link href={href}>
      <Card hoverable styles={{ body: { padding: 0 } }}>
        <div
          style={{
            fontSize: '80px',
            color: process.env.NEXT_PUBLIC_PRIMARY_COLOR,
            margin: 'auto auto',
            textAlign: 'center'
          }}
        >
          {icon}
        </div>
        <Title level={4} style={{ textAlign: 'center', marginTop: '20px' }}>
          {t(title)}
        </Title>
      </Card>
    </Link>
  )
}
export default LinkCard
